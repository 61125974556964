<template>
    <svg :width="props.size" :height="props.size">
        <g :transform="props.transform" stroke="none">

            <!-- a -->
            <path :fill="props.color" d="M2796 7060 c-186 -30 -349 -122 -430 -245 -34 -50 -76 -151 -76 -180
0 -9 327 -90 364 -90 4 0 17 23 27 52 13 32 36 66 63 91 166 153 488 36 416
-152 -16 -43 -60 -56 -256 -77 -272 -28 -371 -55 -492 -138 -108 -73 -162
-183 -162 -330 0 -147 60 -266 170 -339 81 -55 153 -75 260 -75 229 1 380 102
466 311 l24 57 0 -172 0 -173 191 0 190 0 -3 518 c-5 570 -3 558 -71 685 -56
107 -198 208 -338 242 -80 19 -265 27 -343 15z m354 -953 c-59 -158 -261 -267
-412 -223 -61 17 -94 47 -108 96 -18 64 18 133 88 170 13 6 106 22 206 35 101
12 196 29 212 36 l29 14 3 -36 c2 -19 -6 -61 -18 -92z" />

            <!-- r -->
            <path :fill="props.color" d="M4517 7060 c-132 -23 -252 -143 -309 -308 l-23 -67 -3 178 -2 177
-185 0 -185 0 0 -545 0 -545 184 0 185 0 3 268 c3 254 4 269 26 317 75 164
263 229 480 166 l32 -10 0 179 c0 176 0 178 -22 183 -49 12 -135 15 -181 7z" />

            <!-- t -->
            <path :fill="props.color" d="M5285 7521 l-180 -57 -3 -212 -2 -212 -145 0 -145 0 0 -165 0 -165
145 0 145 0 0 -269 c0 -400 17 -517 91 -633 58 -91 152 -152 281 -184 35 -8
132 -18 221 -21 l157 -6 0 165 0 165 -117 5 c-123 5 -174 19 -206 56 -42 47
-46 83 -47 410 l0 312 185 0 185 0 0 165 0 165 -185 0 -185 0 0 270 c0 149 -3
270 -7 269 -5 0 -89 -26 -188 -58z" />

            <!-- l -->
            <path :fill="props.color" d="M3810 4810 l0 -1030 185 0 185 0 0 1030 0 1030 -185 0 -185 0 0
-1030z" />

            <!-- oo -->
            <path :fill="props.color" d="M5019 5240 c-298 -38 -542 -243 -631 -530 -33 -109 -33 -312 0 -420
81 -258 272 -443 529 -511 105 -28 307 -31 408 -5 83 22 196 72 260 116 l46
32 62 -41 c204 -133 467 -164 707 -85 110 37 205 97 290 183 141 143 200 283
208 492 4 105 1 139 -17 209 -54 214 -184 376 -383 480 -114 60 -209 82 -353
83 -181 1 -301 -32 -440 -121 -79 -50 -58 -53 -180 22 -131 80 -333 119 -506
96z m71 -195 c35 -18 87 -75 99 -108 16 -42 13 -107 -8 -152 -59 -128 -225
-153 -323 -49 -83 89 -58 247 47 302 63 33 129 35 185 7z m1195 -179 c151 -68
233 -215 223 -396 -7 -114 -44 -194 -125 -271 -82 -78 -148 -102 -268 -97
-111 5 -174 34 -252 116 -54 56 -62 73 -24 53 11 -6 22 -8 24 -4 36 64 42 325
10 435 l-20 67 36 34 c102 98 264 123 396 63z m-1528 -108 c47 -44 14 -128
-49 -128 -41 0 -63 14 -77 49 -10 24 -10 34 3 59 9 17 21 32 28 35 31 13 73 6
95 -15z" />

            <!-- p -->
            <path :fill="props.color" d="M7850 5239 c-78 -13 -186 -69 -248 -128 -58 -57 -131 -163 -161 -237
l-20 -49 0 198 -1 197 -190 0 -190 0 0 -1000 0 -1000 188 2 187 3 3 475 3 475
24 -57 c121 -294 403 -431 686 -334 184 64 337 239 401 462 31 108 34 380 4
489 -67 249 -236 437 -440 491 -70 19 -180 25 -246 13z m102 -371 c75 -35 142
-103 181 -186 30 -63 32 -73 32 -183 0 -113 -1 -116 -38 -191 -45 -93 -113
-157 -195 -188 -83 -31 -203 -25 -289 14 -76 35 -153 113 -191 193 -24 53 -27
69 -27 173 0 103 3 121 27 172 29 63 92 137 145 169 104 64 249 75 355 27z" />

        </g>
    </svg>
</template>

<script setup>

const props = defineProps({
    size: String,
    transform: String,
    color: String,
})

</script>

<style lang="scss" scoped></style>

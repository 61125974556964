<template>
    <svg v-if="props.size == 'P'" width='30' height='72'>
        <line v-if='props.index % 2' x1='10' y1='7' x2='20' y2='64' :stroke='props.color' stroke-width='4' stroke-linecap='round'/>
        <line v-else x1='20' y1='7' x2='10' y2='64' :stroke='props.color' stroke-width='4' stroke-linecap='round'/>
    </svg>
    <svg v-if="size == 'M'" width='30' height='99'>
        <line v-if='props.index % 2' x1='10' y1='7' x2='20' y2='92' :stroke='props.color' stroke-width='5' stroke-linecap='round'/>
        <line v-else x1='20' y1='7' x2='10' y2='92' :stroke='props.color' stroke-width='5' stroke-linecap='round'/>
    </svg>
    <svg v-if="size == 'G'" width='30' height='127'>
        <line v-if='props.index % 2' x1='10' y1='6' x2='20' y2='121' :stroke='props.color' stroke-width='6' stroke-linecap='round'/>
        <line v-else x1='20' y1='6' x2='10' y2='121' :stroke='props.color' stroke-width='6' stroke-linecap='round'/>
    </svg>
</template>

<script setup>

const props = defineProps({
    size: String,
    index: Number,
    color: String,
})

</script>

<style lang='scss' scoped></style>

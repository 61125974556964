<template>
    <div class="product">
        <div v-if="props.products.length >= 1">
            <div>
                <Carousel :autoplay="autoPlay" :wrap-around="true">
                    <Slide v-for="products in productsList" :key="products.id" class="slide">
                        <PriceColumn :size="props.cardSize" :showUnits="props.showUnits" :products="products"
                            :cardColor="props.cardColor" :cardBarColor="props.cardBarColor" :txtColor="props.txtColor"
                            :highlightedColor="props.highlightedColor" />
                    </Slide>
                </Carousel>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

import PriceColumn from "@/components/PriceColumn.vue";


const props = defineProps({
    numCards: Number,
    cardSize: String,
    showUnits: Boolean,
    products: Array,
    cardColor: String,
    cardBarColor: String,
    txtColor: String,
    highlightedColor: String,
})

const productsList = computed(() => {
    let newList = [];
    let idx = 1;
    let aux = 1;

    var subLista = []
    for (const productID in props.products) {
        subLista.push(props.products[productID]);
        if (aux >= props.numCards || idx == props.products.length) {
            newList.push(subLista);
            subLista = [];
            aux = 0;
            if (newList.length == 3) {
                return newList;
            }
        }
        aux++;
        idx++;
    }
    return newList;
})

const autoPlay = computed(() => {
    return props.products.length > props.numCards ? 30000 : 0;
})

</script>

<style lang="scss" scoped>
.product {
    display: inline;
    margin: 5px 10px;
    height: calc(82vh - 10px);
    width: calc((100vw - 40px)/2);
}

.slide {
    height: calc(82vh - 10px);
    width: calc((100vw - 40px)/2);
}
</style>

<template>
    <div class="double-column">
        <PriceCard v-for="(product, i) in products.slice(0, products.length)" :showUnits="props.showUnits" :product="product" :index="i"
            :size="props.size" :color="props.cardColor" :barColor="props.cardBarColor" :txtColor="props.txtColor"
            :priceColor="props.highlightedColor" />
    </div>
</template>

<script setup>
import PriceCard from '@/components/PriceCard.vue';

const props = defineProps({
    size: String,
    showUnits: Boolean,
    products: Array,
    cardColor: String,
    cardBarColor: String,
    txtColor: String,
    highlightedColor: String,
})

</script>

<style lang="scss" scoped>
.double-column {
    margin: 5px 10px;
    width: calc((100vw - 40px)/2);
    height: calc(82vh - 10px);
}
</style>

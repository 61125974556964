import { ref } from "vue";
import axios from 'axios'
import store from '@/store/index';
import { is_not_empty_str } from "@/utils/functions";
import { encrypt, decrypt } from "@/utils/crypt";

//  URLS
const BASE_URL = process.env.VUE_APP_BASE_URL;
const STATUS_URL = 'status/';
const LOGIN_URL = 'auth/token/login/';
const LOAD_DEVICE_DATA_URL = 'signup/load_device_data/';

const imageBlobs = ref({});

function getToken() {
    return decrypt(store?.state?.token);
}

function setToken(token) {
    store.state.token = encrypt(token);
}

function getUserLogin() {
    return store?.state?.userLogin
}


//  AXIOS CONFIG
const mp_api = axios.create({
    baseURL: BASE_URL,
    timeout: 5000
})


//  AXIOS INTERCEPTORS
mp_api.interceptors.request.use(
    (config) => {
        const authToken = getToken();
        if (!config.url.includes("login") && is_not_empty_str(authToken)) {
            config.headers.Authorization = `Token ${authToken}`;
        }
        return config;
    },
    (error) => {
        // console.log(error);
        return Promise.reject(error);
    }
);

mp_api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response) {
            if (error.response.status === 401 && !mp_api._retry) {
                // Tenta Login, após falha de permissão
                mp_api._retry = true;
                const userLogin = getUserLogin();
                if (userLogin === undefined) {
                    return Promise.reject(error.response);
                }

                try {
                    const response = await loginService(
                        userLogin.email,
                        decrypt(userLogin.password)
                    );
                    const { auth_token } = response.data;
                    setToken(auth_token);
                    return Promise.reject(error);
                } catch (_error) {
                    // console.log(_error);
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }
                    return Promise.reject(_error);
                }
            }
            mp_api._retry = false;
            if (error.response.status === 403 && error.response.data) {
                return Promise.reject(error.response.data);
            }
        }

        return Promise.reject(error);
    }
);


//  API SERVICES
async function getStatus() {
    return (await mp_api.get(STATUS_URL)).data;
}

/**
 * @param {string} email
 * @param {string} password
*/
async function loginService(email, password) {
    const credentials = {
        email: email,
        password: password
    }
    return await mp_api.post(LOGIN_URL, credentials);
}

async function getOrCreateImageBlob(image) {
    if (image === undefined || image === null) {
        return "";
    }

    const imageKEY = image.id
    var imageData = imageBlobs.value[imageKEY]
    if (imageData === undefined || imageData.updated_at !== image.updated_at) {
        const imageBlob = URL.createObjectURL(await fetch(image.url).then(res => res.blob()));
        imageBlobs.value[imageKEY] = { blob: imageBlob, updated_at: image.updated_at };
        return imageBlob;
    }
    return imageData?.blob;
}

async function loadDeviceDataService(screen_idx) {
    const reponseData = (await mp_api.get(LOAD_DEVICE_DATA_URL)).data;
    const numScreens = reponseData.screens?.length

    if (numScreens === undefined || screen_idx > numScreens - 1) {
        return
    }

    const screen = reponseData.screens[screen_idx]
    let logo = screen.style?.logo;
    let bg_image = screen.style?.bg_image;

    const logoURL = await getOrCreateImageBlob(logo);
    if (is_not_empty_str(logoURL)) {
        screen.style.logo.url = logoURL;
    }

    const bgImageURL = await getOrCreateImageBlob(bg_image);
    if (is_not_empty_str(bgImageURL)) {
        screen.style.bg_image.url = bgImageURL;
    }

    for (const posterID in screen.posters) {
        let poster = screen.posters[posterID];
        poster.image.url = await getOrCreateImageBlob(poster.image);
    }
    reponseData.screen = screen;

    return reponseData;
}

export { getStatus, loginService, loadDeviceDataService }

<template>
    <div class="login-navbar">
        <span class="title">artloop</span>
        <button class="btn-home" @click="goToHome()">Início</button>
    </div>

    <div class="page" :style="{ backgroundColor: pageColor }">
        <div class="card" :style="{ backgroundColor: cardColor }">
            <div class="title">
                <span>Painel Artloop</span>
            </div>
            <span v-if="loginFailed" class="login-error" style="margin-bottom: 10px;">
                Usuário ou senha incorretos
            </span>
            <div class="input">
                <span>Email </span>
                <input id="id-email" />
            </div>
            <div class="input" style="margin-top: 10px;">
                <span>Senha </span>
                <input id="id-password" type="password" />
            </div>
            <button id="id-btn-login" class="button" @click="login()">
                Entrar
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { loginService } from '@/services/mp_api'
import { encrypt } from "@/utils/crypt";

const store = useStore();
const router = useRouter();
const loading = ref(false);
const loginFailed = ref(false);

const pageColor = ref("#202020");
const cardColor = ref("#dddddd");


const login = async () => {
    loading.value = true;
    try {
        const email = document.getElementById("id-email").value;
        const password = document.getElementById("id-password").value;
        const response = await loginService(
            email,
            password
        );
        const { auth_token } = response.data;
        loading.value = false;
        store.dispatch('setUserLogin', { email: email, password: encrypt(password) });
        store.dispatch('setToken', encrypt(auth_token));
        goToHome();
    } catch (error) {
        loading.value = false;
        loginFailed.value = true;
    }
}

const goToHome = () => {
    router.push({ name: "home" });
}
</script>


<style lang="scss" scoped>
.login-navbar {
    display: flex;
    height: 50px;
    background-color: #005b56;
    align-items: center;
    justify-content: space-between;

    .title {
        margin-left: 50px;
        color: rgb(255, 255, 255);
        font-weight: 600;
        font-size: 25px;
    }

    .btn-home {
        background-color: #fffdd2;
        /* Green */
        border: none;
        color: black;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
        margin-right: 20px;
        transition-duration: 0.4s;
        border-radius: 5px;
    }

    .btn-home:hover {
        background-color: #ffaa44;
        /* Green */
        color: white;
    }
}

.page {
    height: calc(100vh - 50px);
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        padding: 20px 30px;
        background-color: white;

        .title {
            padding: 20px 50px;
            padding-bottom: 30px;
            font-size: 40px;
            font-weight: 600;
            color: #005b56;
        }

        .login-error {
            color: red;
            font-size: large;
            font-weight: 600;
        }

        .input {
            width: 350px;
            padding: 0;
            display: flex;
            flex-direction: column;

            input {
                width: 100%;
                padding: 12px 20px;
                margin: 8px 0;
                display: inline-block;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-sizing: border-box;
            }

            v-text-field {
                padding-top: 5px;
            }

            span {
                font-size: medium;
                font-weight: 400;
                padding-bottom: 5px;
            }
        }

        .button {
            margin: 10px 0px;
            display: inline-block;
            padding: 10px 30px;
            font-size: 15px;
            font-weight: 700;
            cursor: pointer;
            text-align: center;
            text-decoration: none;
            outline: none;
            color: white;
            background-color: #005b56;
            border: none;
            border-radius: 5px;
        }

        .button:hover {
            background-color: #227777;
            /* Green */
            color: white;
        }
    }
}
</style>
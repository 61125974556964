<template>
    <div class="home_navbar" :style="{ backgroundColor: navBarColor }">
        <span class="title">artloop</span>
        <button class="button" @click="goToLogin()">Login</button>
    </div>
    <div class="page" :style="{ backgroundColor: pageColor }">
        <Logo :size="logoSize" :transform="logoTransform" :color="logoColor"/>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router'
import Logo from "@/components/Logo.vue";

const router = useRouter();

const pageColor = ref("#202020");
const logoSize = ref("432");
const logoTransform = ref("translate(0,432) scale(0.04,-0.04)");
const navBarColor = ref("#005b56");
const logoColor = ref("#ffffff");

const goToLogin = () => {
    router.push({ name: "login" });
}
</script>

<style lang="scss" scoped>
.home_navbar {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;

    .title {
        margin-left: 50px;
        color: rgb(255, 255, 255);
        font-weight: 600;
        font-size: 25px;
    }

    .button {
        background-color: #fffdd2;
        /* Green */
        border: none;
        color: black;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
        margin-right: 20px;
        transition-duration: 0.4s;
        border-radius: 5px;
    }

    .button:hover {
        background-color: #ffaa44;
        /* Green */
        color: white;
    }
}

.page {
    height: calc(100vh - 50px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

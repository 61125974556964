import { is_not_empty_str } from "@/utils/functions";
var CryptoJS = require("crypto-js");


const SECRET_KEY = process.env.VUE_APP_SECRET_KEY;


function encrypt(strData) {
    if (!is_not_empty_str(strData)) {
        return
    }
    return CryptoJS.AES.encrypt(strData, SECRET_KEY).toString();
}

function decrypt(strData) {
    if (!is_not_empty_str(strData)) {
        return
    }
    return CryptoJS.AES.decrypt(strData, SECRET_KEY).toString(CryptoJS.enc.Utf8);
}

export { encrypt, decrypt }

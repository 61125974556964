<template>
    <div v-if="posters.length >= 1" :class="posterClass">
        <Carousel :autoplay="autoPlay" :wrap-around="true">
            <Slide v-for="poster in posters" :key="poster.id" :class="slideClass">
                <PosterSlide :poster="poster" :ScreenMode="props.ScreenMode" :showUnits="props.showUnits"
                    :txtColor="highlightedColor" :bgColor="cardColor" />
            </Slide>
        </Carousel>
    </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

import PosterSlide from "@/components/PosterSlide.vue";


const props = defineProps({
    posters: Array,
    ScreenMode: String,
    showUnits: Boolean,
    highlightedColor: String,
    cardColor: String,
})

const posterClass = computed(() => {
    return props.ScreenMode === 'MULTI_TASK' ? 'poster' : 'poster__full';
})

const slideClass = computed(() => {
    return props.ScreenMode === 'MULTI_TASK' ? 'slide' : 'slide__full';
})

const autoPlay = computed(() => {
    return props.posters.length > 1 ? 10000 : 0;
})

</script>

<style lang="scss" scoped>
.poster {
    margin: 5px 10px;
    height: calc(82vh - 10px);
    width: calc((100vw - 40px)/2);

    &__full {
        height: calc(100vh) !important;
        width: calc(100vw) !important;
    }
}

.slide {
    display: inline;
    height: calc(82vh - 10px);
    width: calc((100vw - 40px)/2);

    &__full {
        display: inline !important;
        height: calc(100vh) !important;
        width: calc(100vw) !important;
    }
}
</style>

import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        userLogin: null,
        token: null,
        screens: null,
        place: null,
        cmd: null,
    },
    getters: {
        userLogin: (state) => state.userLogin,
        token: (state) => state.token,
        screens: (state) => state.screens,
        place: (state) => state.place,
        cmd: (state) => state.cmd,
    },
    actions: {
        setUserLogin({ commit }, payload) {
            commit('SET_USER_LOGIN', payload);
        },
        setToken({ commit }, payload) {
            commit('SET_TOKEN', payload);
        },
        setScreens({ commit }, payload) {
            commit('SET_SCREENS', payload);
        },
        setPlace({ commit }, payload) {
            commit('SET_PLACE', payload);
        },
        setCMD({ commit }, payload) {
            commit('SET_CMD', payload);
        },
    },
    mutations: {
        SET_USER_LOGIN(state, payload) {
            state.userLogin = payload;
        },
        SET_TOKEN(state, payload) {
            state.token = payload;
        },
        SET_SCREENS(state, payload) {
            state.screens = payload;
        },
        SET_PLACE(state, payload) {
            state.place = payload;
        },
        SET_CMD(state, payload) {
            state.cmd = payload;
        },
    }
})

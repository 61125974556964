
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function is_str(data) {
    return typeof (data) === "string";
}

function is_not_empty_str(data) {
    return is_str(data) && data != "";
}

function formatUnit(measure_unit) {
    switch (measure_unit) {
        case 'KILOGRAM':
            return 'Kg';
        case 'LITER':
            return 'Litro';
        case 'METER':
            return 'Metro';
        case 'METER_2':
            return'm²';
        case 'METER_3':
            return'm³';
        case 'PART':
            return 'Peça';
        case 'DOZEN':
            return 'Dúzia';
        case 'COMB':
            return 'Pente';
        case 'GALLON':
            return 'Galão';
        case 'TON':
            return 'Ton';
        case 'POUND':
            return 'Libra';
        case 'BOWL':
            return 'Pote';
        case 'BOX':
            return 'Caixa';
        default:
            return 'Un';
    }
}

export { sleep, is_str, is_not_empty_str, formatUnit };

<template>
    <section class="page_404">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 ">
                    <div class="col-sm-10 col-sm-offset-1  text-center">
                        <div class="four_zero_four_bg">
                            <h1 class="text-center ">404</h1>

                        </div>

                        <div class="contant_box_404">
                            <h3 class="h2">
                                Parece que você está perdido
                            </h3>

                            <p>Esta página não está disponível!</p>

                            <a @click="goToHome()" class="link_404">Ir para Home</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script setup>
import { useRouter } from 'vue-router'

const router = useRouter();

const goToHome = () => {
    router.push({ name: "home" });
}

</script>

<style lang="scss" scoped>
@import 'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/css/bootstrap.min.css';
@import 'https://fonts.googleapis.com/css?family=Arvo';

.page_404 {
    padding: 40px 0;
    background: #fff;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url('@/assets/shock.gif');
    height: 400px;
    background-position: center;
}


.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    border-radius: 5px;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    cursor: pointer;
}

.contant_box_404 {
    margin-top: -50px;
}
</style>
  
<template>
    <div class="main">
        <RouterView />
        <div class="footer">
            ©{{ anoAtual }} artloop - todos os direitos reservados.
        </div>
    </div>
</template>

<script setup>
import { RouterView } from 'vue-router'

// Ano Atual
const anoAtual = new Date().getFullYear();
</script>

<style>
.title{
    font-family: 'Atyp BL Semibold', sans-serif;
}

.main {
    font-family: 'Montserrat', system-ui;
}

body {
    margin: 0;
    overflow: hidden;
}

.footer {
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 20px;
    color: #ffffff;
    margin: 0px;
}
</style>

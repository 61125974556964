<template>
    <div class="product_card" :class="cardClass" :style="{ background: props.color, color: props.txtColor }">
        <span class="product-name" :class="productNameClass">{{ props.product.name }}</span>
        <div style="display: flex;">
            <CardBar :size="props.size" :index="props.index" :color="props.barColor" />
            <div class="info" :class="infoClass" >
                <span v-if="props.showUnits" class="unit" :class="unitClass">
                    {{ formattedUnit }}
                </span>
                <span class="price" :class="priceClass" :style="{ color: props.priceColor }">{{ formattedPrice }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from '@vue/reactivity';
import CardBar from '@/components/CardBar.vue'
import { formatUnit } from "@/utils/functions"


const props = defineProps({
    showUnits: Boolean,
    product: Object,
    index: Number,
    size: String,
    showUnit: Boolean,
    color: String,
    barColor: String,
    txtColor: String,
    priceColor: String,
})

const cardClass = computed(() => {
    switch (props.size) {
        case 'P':
            return 'product_card__small'
        case 'M':
            return 'product_card__medium'
        case 'G':
            return 'product_card__big'
        default:
            return ''
    }
})

const productNameClass = computed(() => {
    switch (props.size) {
        case 'P':
            return 'product-name__small'
        case 'M':
            return 'product-name__medium'
        case 'G':
            return 'product-name__big'
        default:
            return ''
    }
})

const priceClass = computed(() => {
    switch (props.size) {
        case 'P':
            return 'price__small'
        case 'M':
            return 'price__medium'
        case 'G':
            return 'price__big'
        default:
            return ''
    }
})

const infoClass = computed(() => {
    switch (props.size) {
        case 'P':
            return 'info__small'
        case 'M':
            return 'info__medium'
        case 'G':
            return 'info__big'
        default:
            return ''
    }
})

const unitClass = computed(() => {
    switch (props.size) {
        case 'P':
            return 'unit__small'
        case 'M':
            return 'unit__medium'
        case 'G':
            return 'unit__big'
        default:
            return ''
    }
})

const formattedUnit = computed(() => {
    return formatUnit(props.product.measure_unit)
})

const formattedPrice = computed(() => {
    const priceNumber = props.product.price.toFixed(2)
    const priceString = (priceNumber + "").replace(".", ",")
    return priceString
})

</script>

<style lang="scss" scoped>
.product_card {
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    text-align: start;

    &__small {
        height: 60px !important;
    }

    &__medium {
        height: 90px !important;
    }

    &__big {
        height: 120px !important;
    }

    .product-name {
        font-weight: 600;
        line-height: 0.95;
        margin-left: 10px;

        &__small {
            font-size: 30px;
        }

        &__medium {
            font-size: 35px;
        }

        &__big {
            font-size: 45px;
        }
    }

    .info{
        border: 15px;
        align-self: center;

        &__small {
            width: 120px;
        }

        &__medium {
            width: 150px;
        }

        &__big {
            width: 200px;
        }
    }

    .unit {
        display: block;
        text-align: center;
        font-weight: bold;

        &__small {
            height: 18px;
            font-size: 16px;
        }

        &__medium {
            height: 24px;
            font-size: 20px;
        }

        &__big {
            height: 30px;
            font-size: 26px;
        }
    }

    .price {
        display: block;
        text-align: center;
        font-family: 'Cambria';
        font-weight: bold;

        &__small {
            height: 38px;
            font-size: 30px;
        }

        &__medium {
            height: 55px;
            font-size: 42px;
        }

        &__big {
            height: 70px;
            font-size: 55px;
        }
    }
}

.inactive {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;

    img {
        max-width: 100%;
        max-height: 80vh;
        margin: auto;
    }
}
</style>
